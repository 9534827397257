import http from '@/utils/http'

// export function getBusLocTree(p) {
//     return http.post('/unibu/monitor/vehi/getBusLocTree', p)
// }

// 获取砂浆罐树
export const getPotTree = p => http.post('/unibu/pot/getPotTree', p)

// 获取车相关的树
export function getBusTree (p) {
  return http.post('/unibu/monitor/vehi/getBusLocTree', p)
}

// 获取首页数据
export function getHomeData (p) {
  return http.post('/unibu/monitor/home/getHomePage', p)
}

// 获取车辆列表
export function getBusList (p) {
  return http.post('/unibu/monitor/vehi/selectAll', p)
}
