<template>
  <page-container>
    <div>
      <p class="bold fs-16 mb-10">车辆监控</p>
      <div class="mb-28">
        <div class="data-title">
          <img src="@/assets/imgs/monitor/home/bus-icon.png" alt="">
          <span class="ml-12">车辆监控</span>
        </div>
        <div class="data-content flex justify-around">
          <div class="data-item-underline">
            <span class="data-item-title">车辆总数</span>
            <p class="data-item-value-box">
              <count-to class="data-item-value" :endVal="busTotal" :duration="2000" />
            </p>
          </div>
          <div class="data-item-underline">
            <span class="data-item-title" style="border-color:#FDAD42;">今日在线车辆数</span>
            <p class="data-item-value-box">
              <count-to class="data-item-value" :endVal="busOnTotal" :duration="2000" />
            </p>
          </div>
          <div class="data-item-underline">
            <span class="data-item-title" style="border-color:#FF6AB6;">在线率</span>
            <p class="data-item-value-box">
              <count-to class="data-item-value" :endVal="busPercent" :duration="2000" :decimals="2" suffix="%" />
            </p>
          </div>
          <div class="data-item-underline">
            <span class="data-item-title" style="border-color:#63B7FF;">已处理数/日报警数</span>
            <p class="data-item-value-box">
              <count-to class="data-item-value" style="margin-right:0;" :endVal="dayDeal" :duration="2000" suffix="/" />
              <count-to class="data-item-value" :endVal="dayTotal" :duration="2000" />
            </p>
          </div>
          <div class="data-item-underline">
            <span class="data-item-title" style="border-color:#C27DFF;">已处理数/月报警数</span>
            <p class="data-item-value-box">
              <count-to class="data-item-value" style="margin-right:0;" :endVal="monthDeal" :duration="2000" suffix="/" />
              <count-to class="data-item-value" :endVal="monthTotal" :duration="2000" />
            </p>
          </div>
          <div class="data-item-underline">
            <span class="data-item-title" style="border-color:#4EF0C5;">已处理数/年报警数</span>
            <p class="data-item-value-box">
              <count-to class="data-item-value" style="margin-right:0;" :endVal="yearDeal" :duration="2000" suffix="/" />
              <count-to class="data-item-value" :endVal="yearTotal" :duration="2000" />
            </p>
          </div>
          <div class="data-item-underline">
            <span class="data-item-title" style="border-color:#396CE2;">监控设备总数</span>
            <p class="data-item-value-box">
              <count-to class="data-item-value" :endVal="buscount" :duration="2000" />
            </p>
          </div>
          <div class="data-item-underline">
            <span class="data-item-title" style="border-color:#F57A7A;">在线设备总数</span>
            <p class="data-item-value-box">
              <count-to class="data-item-value" :endVal="busOncount" :duration="2000" />
            </p>
          </div>
        </div>
      </div>

      <div class="flex">
        <div class="flex-1 mr-10">
          <p class="bold fs-16 mb-10">场地视频</p>
          <div >
            <div class="data-title" style="background: linear-gradient(90deg, #6B21D6 0%, #FFFFFF 100%);">
              <img src="@/assets/imgs/monitor/home/video-icon.png">
              <span class="ml-12">场地视频</span>
            </div>
            <div class="data-content2 flex justify-around">
              <div class="data-item">
                <span class="data-item-title2 flex align-center">
                  <img src="@/assets/imgs/monitor/home/video-icon1.png">
                  <span class="ml-10">监控设备总数</span>
                </span>
                <p class="data-item-value-box">
                  <count-to class="data-item-value" :endVal="yardcount" :duration="2000" />
                </p>
              </div>
              <div class="data-item">
                <span class="data-item-title2 flex align-center">
                  <img src="@/assets/imgs/monitor/home/video-icon2.png">
                  <span class="ml-10">在线设备总数</span>
                </span>
                <p class="data-item-value-box">
                  <count-to class="data-item-value" :endVal="yardOncount" :duration="2000" />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-1 ml-10">
          <p class="bold fs-16 mb-10">砂浆罐监控</p>
          <div>
            <div class="data-title" style="background: linear-gradient(90deg, #009F8C 0%, #FFFFFF 100%);">
              <img src="@/assets/imgs/monitor/home/sJiang-icon.png">
              <span class="ml-12">砂浆罐监控</span>
            </div>
            <div class="data-content2 flex justify-around">
              <div class="data-item">
                <span class="data-item-title2 flex align-center">
                  <img src="@/assets/imgs/monitor/home/monitor-icon1.png">
                  <span class="ml-10">监控设备总数</span>
                </span>
                <p class="data-item-value-box">
                  <count-to class="data-item-value" :endVal="potTotal" :duration="2000" />
                </p>
              </div>
              <div class="data-item">
                <span class="data-item-title2 flex align-center">
                  <img src="@/assets/imgs/monitor/home/monitor-icon2.png">
                  <span class="ml-10">在线设备总数</span>
                </span>
                <p class="data-item-value-box">
                  <count-to class="data-item-value" :endVal="potOnTotal" :duration="2000" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-container>
</template>

<script>
import { getHomeData } from '@/apis/monitor'
import CountTo from 'vue-count-to'
export default {
  name: 'monitor',
  data () {
    return {
      busOnTotal: 0, // 在线车辆数
      busOncount: 0, // 车辆监控-在线设备总数
      busPercent: 0, // 在线率（%）
      busTotal: 0, // 车辆总数
      buscount: 0, // 车辆监控-监控设备总数
      // 已处理数/日报警数
      dayDeal: 0, // 已处理数
      dayTotal: 0, // 日报警数
      monthDeal: 0, // 已处理数/月报警数
      monthTotal: 0, // 月报警总数
      potOnTotal: 0, // 砂浆罐监控-在线设备总数
      potTotal: 0, // 砂浆罐监控-监控设备总数
      yardOncount: 0, // 场地视频-在线设备总数
      yardcount: 0, // 场地视频-监控设备总数
      yearDeal: 0, // 已处理数/年报警数
      yearTotal: 0, // 年报警总数
      loading: false
    }
  },
  created () {
    this.loading = true
    getHomeData().then((res) => {
      this.loading = false
      if (res.code == 200) {
        Object.keys(res.data).forEach((key) => {
          if (res.data[key] !== undefined && res.data[key] !== null) this[key] = Number(res.data[key])
        })
      } else {
        this.$message.error(res.msg)
      }
    })
  },
  components: {
    CountTo
  }
}
</script>

<style lang="scss" scoped>
.data-title {
  width: 100%;
  height: 53px;
  background: linear-gradient(90deg, #1532E3 0%, #FFFFFF 100%);
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  line-height: 53px;
  padding-left: 17px;
  display: flex;
  align-items: center;
}
.data-content {
  background: #fff;
  padding: 27px 12px;
  font-size: 12px;
  color: #6C7B8A;
}
.data-content2 {
  background: #fff;
  padding: 27px 12px 12px;
  font-size: 12px;
  color: #6C7B8A;
}
.data-item-underline {
  // width: 241px;
  width: 22%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #F3F5F8;
}
.data-item {
  width: 47%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.data-item-title {
  display: inline-block;
  height: 14px;
  line-height: 14px;
  border-left: 6px solid #5D7DEC;
  padding-left: 6px;
}
.data-item-title2 {
  padding-left: 10px;
}
.data-item-value-box {
  height: 14px;
}
.data-item-value {
  display: inline-block;
  height: 14px;
  line-height: 14px;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  margin-right: 6px;
  vertical-align: middle;
}
.data-item-unit {
  display: inline-block;
  height: 14px;
  line-height: 14px;
  vertical-align: middle;
}
</style>
